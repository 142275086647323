const esTickets = {
  salesChannels: 'Canales de venta',
  all: 'Todos los canales de venta',
  web: 'Web Checkout',
  insiders: 'Insiders Mobile',
  sellIn: 'Vender en',
  validChannels: 'Debes contar con al menos un canal de venta. ',
  confirmDialog: {
    title: '¿Estás seguro de quitar un canal de ventas?',
    content:
      'Esto podría afectar el volumen de ventas de tus boletos para el evento',
    confirm: 'Sí, quitar canal de ventas',
  },
  cannotDeleteTicket: 'No puedes desactivar el único boleto disponible',
  ticketActivated: 'Boleto activado',
  ticketDeactivated: 'Boleto desactivado',
  ticketDeleted: 'Boleto borrado exitosamente',
  ticketDeleteError: 'Ocurrió un error al borrar este boleto',
  ticketDeleteConfirm: '¿Estas seguro de que deseas eliminar tu boleto?',
  ticketHasSales: 'El boleto tiene ventas asociadas',
};

export default esTickets;
