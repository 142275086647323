import useAuth from './useAuth';
import useUserClient from './useUserClient';

export default function useHasAcceptedContract() {
  const { clientProfile } = useUserClient();

  const { userProfile } = useAuth();

  const hasAcceptedContract =
    clientProfile?.user_profile?.contract_state === 'accepted' ||
    userProfile?.user_profile?.contract_state === 'accepted';

  return { hasAcceptedContract };
}
