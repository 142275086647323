import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router';
import { LoadingScreen } from '@boletia/blt-ui';
import useHasAcceptedContract from '../hooks/useHasAcceptedContract';
import useTester from '../hooks/useTester';

export default function RequireAcceptedContract({ children, testerType }) {
  const { hasAcceptedContract } = useHasAcceptedContract();
  const { isTester, redirectPath } = useTester({ testerType });
  const [component, setComponent] = useState();
  const location = useLocation();

  useEffect(() => {
    if (hasAcceptedContract === undefined || isTester === undefined) {
      setComponent(<LoadingScreen />);
    } else if (hasAcceptedContract || isTester) {
      setComponent(children);
    } else {
      setComponent(
        <Navigate to={redirectPath} state={{ from: location }} replace />,
      );
    }
  }, [hasAcceptedContract, children]);

  return component;
}

RequireAcceptedContract.propTypes = {
  children: PropTypes.node.isRequired,
  testerType: PropTypes.string.isRequired,
};
