const enTickets = {
  salesChannels: 'Sales channels',
  all: 'All sales channels',
  web: 'Web Checkout',
  insiders: 'Insiders Mobile',
  sellIn: 'Sell in',
  validChannels: 'You must have at least one sales channel. ',
  confirmDialog: {
    title: 'Are you sure you want to remove a sales channel?',
    content:
      'This could affect the volume of sales of your tickets for the event',
    confirm: 'Yes, remove sales channel',
  },
  cannotDeleteTicket: 'You cannot deactivate the only available ticket',
  ticketActivated: 'Ticket activated',
  ticketDeactivated: 'Ticket deactivated',
  ticketDeleted: 'Ticket deleted successfully',
  ticketDeleteError: 'An error occurred while deleting the ticket',
  ticketDeleteConfirm: 'Are you sure you want to delete your ticket?',
  ticketHasSales: 'The ticket has sales associated',
};

export default enTickets;
